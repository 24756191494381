@font-face {
  font-family: 'mikes-icons';
  src: url('../fonts/mk-icons.eot');
  src: url('../fonts/mk-icons.eot#iefix') format('embedded-opentype'),
       url('../fonts/mk-icons.woff2') format('woff2'),
       url('../fonts/mk-icons.woff') format('woff'),
       url('../fonts/mk-icons.ttf') format('truetype'),
       url('../fonts/mk-icons.svg#mikes-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "mikes-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mail:before { content: '\e800'; } /* '' */
.icon-heart:before { content: '\e801'; } /* '' */
.icon-heart-empty:before { content: '\e802'; } /* '' */
.icon-star:before { content: '\e803'; } /* '' */
.icon-star-empty:before { content: '\e804'; } /* '' */
.icon-cancel:before { content: '\e805'; } /* '' */
.icon-picture:before { content: '\e806'; } /* '' */
.icon-attach:before { content: '\e807'; } /* '' */
.icon-link:before { content: '\e808'; } /* '' */
.icon-bookmark:before { content: '\e809'; } /* '' */
.icon-book:before { content: '\e80a'; } /* '' */
.icon-megaphone:before { content: '\e80b'; } /* '' */
.icon-up-open:before { content: '\e80c'; } /* '' */
.icon-down-open:before { content: '\e80d'; } /* '' */
.icon-calendar:before { content: '\e80e'; } /* '' */
.icon-tiktok:before { content: '\e80f'; } /* '' */
.icon-paper-plane:before { content: '\e810'; } /* '' */
.icon-attach-1:before { content: '\e811'; } /* '' */
.icon-ok:before { content: '\e812'; } /* '' */
.icon-heart-1:before { content: '\e813'; } /* '' */
.icon-mail-1:before { content: '\e814'; } /* '' */
.icon-house-solid:before { content: '\e815'; } /* '' */
.icon-bookmark-empty:before { content: '\f097'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-download-cloud:before { content: '\f0ed'; } /* '' */
.icon-coffee:before { content: '\f0f4'; } /* '' */
.icon-mic:before { content: '\f130'; } /* '' */
.icon-dollar:before { content: '\f155'; } /* '' */
.icon-youtube:before { content: '\f167'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-handshake-o:before { content: '\f2b5'; } /* '' */


h1,h2,h3,h4,h5 {
    font-family: $main-font;
    font-weight: 800;
    text-align: center;
    line-height: normal;
    margin: 0;
    padding: 0;
}

h1 {
    font-family: $main-font;
    font-weight: 800;
    font-style: normal;
    color: rgba(25,25,26,1);
    text-transform: uppercase;
    line-height: 1.2;
    letter-spacing: 0.1em;
    font-size: 2.5rem;
    text-align: center;
}

h2 {
    margin-bottom: 15px;
    font-size: 2rem;
    line-height: 2.2rem;
    @media (max-width:380px) { 
        font-size: 1rem;
        line-height: 1.2rem;
    }
    @media (max-width:720px) { 
        font-size: 1rem;
        line-height: 1.3rem;
    }
}

h3 {
    margin-bottom: 15px;
    font-size: 1.75rem;
    @media (max-width:380px) { 
        font-size: 1rem;
    }
}


.tagline {
    display: block;
    font-size: 1.25rem;
    line-height: 1.75rem;
    @media (max-width:380px) { 
        font-size: .9rem;
        line-height: 1.25rem;
    }
    @media (max-width:720px) { 
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

