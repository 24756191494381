.button {
    color: #fff;
    background-color: #000;
    border-radius: 10px;
    list-style-type: none;
    display: inline-block;
    width: 64px;
    height: 64px;
    line-height: 64px;
    cursor: pointer;
    transition: ease .3s;
    font-size: 1.333rem;
    text-align: center;
    box-shadow: 0 0.5rem 1rem rgba(3, 22, 51, 0.15);

    &:hover {
        color: #fff;
        border: 1px solid #fff;
        filter: brightness(90%);
    }

    @each $name, $color in $theme-colors {
        &.--#{$name} {
            background-color: $color;
        }
    }

    &.--instagram {
        background-image: linear-gradient(-135deg, #1400c8, #b900b4, #f50000);
    }
}
