*,
*::after,
*::before {
  position: relative;
  box-sizing: border-box;
}

html {
  font-family: $default;
  font-size: $default-size;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga" on;
}

body {
  margin: 0;
  padding: 0;
  background: $body;
  font-size: $body-size;
  font-family: $main-font;
  color: $text;
  line-height: 1.5;
}

::selection {
  background: $highlight;
}

a {
  color: $links;
  text-decoration: none;

  &:hover {
    color: $hover;
  }
}

strong {
  font-weight: bold;
}

main,
header {
  display: flex;
  flex-direction: column;
}

main {
  max-width: 720px;
  margin: 50px auto;

  @media (max-width: 720px) {
    margin: 25px auto;
    padding-right: 5%;
    padding-left: 5%;
  }
}

header {
    align-items: center;
    text-align: center;
}

.avatar {
  display: block;
  width: 220px;
  height: 220px;
  object-fit: cover;
  background-position: center;
  border-radius: 50%;
  margin-bottom: 15px;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 720px) {
    margin-bottom: 10px;
    width: 128px;
    height: 128px;
  }
}

.socials {
  display: block;
  margin-bottom: 75px;

  @media (max-width: 720px) {
    margin-bottom: 25px;
  }
}

.social-media {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
}

.connections,
.gear {
  margin-bottom: 50px;

  @media (max-width: 720px) {
    margin-bottom: 25px;
  }
}


/* to organize at future date */
.top-bar {
    width: 100%;
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgba(3,22,51,.15); 
    padding: 10px 0;
    h2 {
        margin-bottom: 0;
    }
    @media screen and (min-width: 720px){
      background-color: #000;
      box-shadow: none;
      margin-bottom: 45px;
      h1 {
        color: #fff;
      }
    }
}
.hero-image {
    display: flex;
    flex-direction: column;
    width: 100%;

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    @media screen and (min-width: 720px){
      width: 340px;
      height: 340px;
      overflow: hidden;
      border-radius: 50%;
      img {
        display: inline;
        height: 340px;
      }
    }
}

.tag-line {

    span {
        font-family: $main-font;
        font-weight: 800;
        font-style: normal;
        color: rgba(25,25,26,1);
        text-transform: uppercase;
        font-size: 1.25rem;
    }

    @media screen and (min-width: 720px){
      width: 100%;
      max-width: 720px;
      text-align: center;
      margin: 25px auto;
    }

    @media (max-width: 720px) {
        position: relative;
        top: -25px;

        display: flex;
        flex-direction: column;
        justify-content: center;

        margin-left: auto;
        margin-right: auto;

        width: 90%;
        padding: 20px 15px;

        background: white;
        box-shadow: 0 0.5rem 1rem rgba(3,22,51,.15); 
    }

}


footer {
    width: 100%;
    background: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
}